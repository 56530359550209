<template>
	<div id="pdf-box">
		<DefaultLoading v-if="loading || loadingBar" :loading="loading || loadingBar" />

		<v-container v-if="docError">
			<v-row class="fill-height" align-content="center" justify="center">
				<v-col cols="12">
					<v-alert border="top" colored-border type="error" elevation="2">
						{{ $t("document_error") }}
					</v-alert>
				</v-col>
			</v-row>
		</v-container>
		<v-container v-if="error && !this.otpDialog">
			<v-row class="fill-height mt-5" align-content="center" justify="center">
				<v-col cols="12">
					<v-alert text color="#FF000D" border="left">
						<v-icon color="#FF000D">mdi-close-circle-outline</v-icon>
						<strong>{{ errorMsg }}</strong>
					</v-alert>
				</v-col>
			</v-row>
		</v-container>

		<v-container v-if="status === 'SIGNED'">
			<v-alert class="mt-5" border="left" text type="warning">
				{{ $t("document_already_signed") }}
			</v-alert>
		</v-container>

		<v-container v-if="!docError && status !== 'SIGNED' && !loading">
			<div id="pdf-view" class="col-md-12 col-sm-12 pa-0">
				<pdf-viewer @documentError="documentError($event)"></pdf-viewer>
			</div>
		</v-container>

		<div class="footer-message" v-if="!docError && status !== 'SIGNED' && !loading">
			<div>
				<v-row>
					<v-container class="py-0 fill-height">
						<div class="col-md-8 col-xs-12 text-left terms">
							<v-list>
								<v-list-item>
									<template v-slot:default="{ active }">
										<v-list-item-action>
											<v-checkbox @change="changeConfirm()" dark color="#F36E21"
												v-model="terms_accepted" :input-value="active"></v-checkbox>
										</v-list-item-action>

										<v-list-item-content class="white--text">
											{{ $t("read_understand_doc") }}
										</v-list-item-content>
									</template>
								</v-list-item>
							</v-list>
						</div>
						<div class="col-md-2 col-6 sign">
						<v-btn large color="#47D764" :disabled="!confirm" block dark class="sign-reject-btn"
								:loading="loading" @click="openLocationDialog()">
								<v-icon class="mr-2">mdi-pencil-box-outline</v-icon>
								{{ $t("sign") }}
							</v-btn>
						</div>
						<div class="col-md-2 col-6 reject" v-if="!isNoReject">
							<v-btn large color="#FF000D" class="sign-reject-btn" block dark @click="reject()">
								<v-icon class="mr-2">mdi-close-circle-outline</v-icon>
								{{ $t("reject") }}
							</v-btn>
						</div>
                        <!-- ✅ Mostrar el texto solo si localStorage.list es "true" -->
                        <div v-if="showBackToList" class="text-center mt-3">
                            <v-btn text @click="isLogging" color="white">
                                {{ $t("back_to_documents_list") }}
                            </v-btn>
                        </div>
					</v-container>
				</v-row>
			</div>
		</div>

		<sign-confirm-otp-modal
          :dialog="otpDialog"
          @submitOtpCode="submitOtpCode"
          @update:dialog="otpDialog = $event" />
        <certificate-pin-modal
          :dialog="certificatePinCodeDialog"
          @submitCertificatePin="submitCertificatePin"
          @update:dialog="certificatePinCodeDialog = $event" />
		<sign-confirm-pad-modal
          :dialog="padDialog"
          @submitSignPad="submitSignPad"
          @update:dialog="padDialog = $event" />

	</div>
</template>

<script>
import PdfViewer from "../components/shared/PdfViewer.component";
import SignConfirmOtpModal from "../components/shared/SignConfirmOtpModal.component";
import SignConfirmPadModal from "../components/shared/SignConfirmPadModal.component";
import DefaultLoading from "../components/shared/DefaultLoading.component";
import { SignaturesService } from "../services/signatures-service";
import moment from "moment";
import CertificatePinModal from "../components/shared/CertificatePinModal.component.vue";

export default {
	components: {
		PdfViewer,
		SignConfirmOtpModal,
		SignConfirmPadModal,
		DefaultLoading,
		CertificatePinModal,
	},

	data: function () {
		return {
			terms_accepted: null,
			confirm: false,
			loading: true,
			loadingBar: false,
			docError: false,
			error: false,
			requestId: localStorage.getItem("request_id"),
		//	token: localStorage.getItem("access_token"),
			token: localStorage.getItem("auth_user_token") || localStorage.getItem("access_token"),
			authCitizenId: localStorage.getItem("auth_citizen_id"),
			isAuthCitizen: localStorage.getItem("is_auth_citizen"),
			showPdf: false,
			otpStatus: false,
			otpDialog: false,
			otpSend: false,
			otpCode: "",
			padDialog: false,
			padStatus: false,
			padImage: "",
			userLatitude: "",
			userLongitude: "",
			errorMsg: "",
			submitLocation: false,
			certificatePinCodeDialog: false,
			requestType: ""
		};
	},

	watch: {
		otpSend() {
			if (this.otpStatus) {
				this.otpDialog = true;
				this.padDialog = false;
			}
		},
		submitLocation() {
			this.signDocument();
		},
	},

	computed: {
        showBackToList() {
            let back;
            let user = localStorage.getItem("user");
            let list = localStorage.getItem("list");
            if (user || list) {
                back = true;
                }
            else {
                back = false;
                }
            return back;
        },
		auth() {
			return this.$store.state.common.auth;
		},
		status() {
			return this.$store.state.common.status;
		},
		authId() {
			return this.$store.state.common.authId;
		},
		hasAssociation() {
			return this.$store.getters['common/hasAssociation']
		},
		isNoReject() {
        return localStorage.getItem('noreject') === 'true'}
	},
	mounted() {
		this.loadRequestData();
		this.otpSend = false;
	},
	methods: {

        async waitForDialogClose(dialogName) {
            return new Promise((resolve) => {
                // 🔍 Espera a que Vue actualice el DOM antes de observar cambios
                this.$nextTick(() => {
                    const watcher = this.$watch(dialogName, (newValue) => {
                        console.log(`Esperando cierre de ${dialogName}:`, newValue);
                        if (!newValue) {  // 🔴 Cuando el diálogo se cierra
                            console.log(`${dialogName} ha sido cerrado`);
                            watcher();  // 🛑 Detener el watcher
                            resolve();  // 🔄 Continuar con el siguiente paso
                        }
                    }, { immediate: true }); // 👈🏽 Comienza la observación de inmediato
                });
            });
        },

        isLogging() {
            this.authid="";
            let association=localStorage.getItem("currentAssociation");
            let isCustomized=localStorage.getItem("isCustomizedUser");
            console.log(association);
            console.log(localStorage.getItem("auth_user_token"));

            if (localStorage.getItem("auth_user_token") != "") {
                this.$router.push({
                    name: isCustomized ? "custom-my-signatures" : "my-signatures",
                    params: isCustomized ? { association } : {}
                });

            } else {
                return false;
            }
        },

		changeConfirm() {
			this.confirm = !this.confirm;
		},
		loadRequestData() {
			this.loading = true;
			this.$store
				.dispatch("common/getRequestData", {
					token: this.token,
					requestId: this.requestId,
					authId: this.authId,
				})
				.then((response) => {
					this.loading = false;
					const signRequest = response.data;
					this.$store.commit('forms/setHasPendingSignerForm', this.hasPendingSignerForm);
                    this.$store.commit('attachments/setHasAttachments', this.hasAttachments);

					this.padStatus = signRequest.pad;
					this.otpStatus = signRequest.otp;

					if (signRequest.id == this.$route.params.id) {
						this.document = signRequest.signatureTransaction.description;
						this.signerName = signRequest.signerName;
						this.ownerName = signRequest.signatureTransaction.ownerName;
						this.title = signRequest.signatureTransaction.document.title;
						this.comments = signRequest.signatureTransaction.comments;
						this.date = signRequest.signatureTransaction.dueDate;
					}
				})
				.catch(() => {
					this.loading = false;
				});
		},
		async signDocument() {
            console.log("Dentro de signDocument");
            if (this.isSigning) {
                console.warn("⚠️ Ya se está ejecutando signDocument(), evitando duplicación...");
                return;
            }
            this.isSigning = true;
            this.requestType=localStorage.getItem("type");
            console.log("requestType en signDocument:", this.requestType, typeof this.requestType);

			this.error = false;
			console.log(this.requestType);
			if (this.padStatus) {
                console.log("activando PAD");
				this.padDialog = true;
				await this.waitForDialogClose("padDialog");
			}
			if (this.requestType === "HUMAN") {
                console.log("Mostrando Diálogo de PIN");
                this.certificatePinCodeDialog = true;
                await this.waitForDialogClose("certificatePinCodeDialog");
            }
            if (this.otpStatus) {
                console.log("📌 Enviando OTP...");
                await this.sendOtpCode();  // ✅ Solo se ejecuta UNA VEZ
                return; // ⛔ Evita ejecutar `submitDocument()` hasta recibir OTP
            }

			console.log("Ejecutando submitDocument");
			this.submitDocument();
			this.isSigning = false;

		},
		getSubmitData() {
			let submitData = {
				comments: "",
				location:
					this.userLatitude && this.userLongitude
						? this.userLatitude + ", " + this.userLongitude
						: "",
				lastRefresh: moment(String(new Date())).format("YYYY-MM-DDTHH:mm:ssZZ"),
			};


			console.log(this.requestType);
			if (this.requestType == "ONETIMEWITHID") {
				submitData["authId"] = this.authId;
			}

			if (this.otpStatus) {
				submitData["otp"] = this.otpCode;
			}

			if (this.padStatus) {
				submitData["signatureImage"] = this.padImage;
			}

			if (this.requestType == "HUMAN"){
                submitData["password"] = this.certificatePinCode;
			}

			return submitData;
		},

        async submitDocument() {
            if (this.isSubmitting) {
                console.warn("⚠️ Ya se está enviando el documento, evitando duplicación...");
                return;
            }

            this.isSubmitting = true;
            console.log("📤 Enviando documento a la API...");

            this.error = false;

            if (this.requestId) {
                this.loadingBar = true;

                try {
                    let response = await SignaturesService.signDocument(this.requestId, this.getSubmitData());
                    this.loadingBar = false;
                    this.status = response.data.status;

                    if (response.data.status === "SIGNED") {
                        this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}summary` });
                    }
                } catch (error) {
                    console.error("❌ Error al enviar documento:", error);
                } finally {
                    this.isSubmitting = false;  // ✅ Permite futuras ejecuciones
                }
            } else {
                this.error = true;
            }
        },

        async sendOtpCode() {
            if (this.isSendingOtp) {
                console.warn("⚠️ OTP ya solicitado, evitando duplicación...");
                return;
            }

            this.isSendingOtp = true;
            this.error = false;

            console.log("📌 Enviando OTP a la API...");
            this.otpDialog = true;

            let data = {
                comments: "",
                authId: this.authId,
                location: "",
                lastRefresh: "",
                otp: "",
                signatureImage: "",
            };

            try {
                console.log("📌 Ejecutando SignaturesService.sendOtp...");
                await SignaturesService.sendOtp(this.requestId, data, this.token);
            } catch (error) {
                console.error("❌ Error en sendOtpCode:", error);
            } finally {
                this.isSendingOtp = false;
            }
        },
		reject() {
			this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}rejected` });
		},
		documentError(value) {
			this.docError = value;
		},
submitOtpCode(otpCode) {
  console.log("Recibido OTP:", otpCode);
  this.otpCode = otpCode;

  this.otpDialog = false; // 🔥 Cerrar el diálogo OTP

  this.$nextTick(() => {
    console.log("OTP cerrado, enviando documento...");
    this.submitDocument(); // 💡 Ahora el flujo sigue correctamente
  });
},

submitSignPad(padImage) {
  console.log("Dentro de submitSignPad, imagen recibida:", padImage);
  this.padImage = padImage;

  this.padDialog = false; // 🔥 Cierra el modal PAD

  this.$nextTick(() => {
    // 🔥 Espera a que el modal se cierre antes de continuar
    console.log("PAD cerrado, continuando con PIN o OTP...");

    // 🔥 Si se requiere PIN, se abre el diálogo de PIN
    if (this.requestType === "HUMAN") {
      console.log("Abriendo diálogo de PIN...");
      this.certificatePinCodeDialog = true;
      return; // ⛔ Esperamos a que cierre el PIN antes de continuar
    }

    // 🔥 Si se requiere OTP, se abre el diálogo de OTP
    if (this.otpStatus) {
      console.log("Abriendo diálogo de OTP...");
      this.otpDialog = true;
      return;
    }

    // 🔥 Si no hay más validaciones, enviamos el documento
    console.log("Ejecutando submitDocument()");
    this.submitDocument();
  });
},

submitCertificatePin(pinCode) {
  console.log("Recibido PIN:", pinCode);
  this.certificatePinCode = pinCode;

  this.certificatePinCodeDialog = false; // 🔥 Cerrar el diálogo de PIN

  this.$nextTick(() => {
    console.log("PIN cerrado, verificando OTP...");

    if (this.otpStatus) {
      console.log("Abriendo diálogo de OTP...");
      this.otpDialog = true;
      return; // ⛔ Esperamos a que OTP se cierre antes de continuar
    }

    console.log("Ejecutando submitDocument()");
  //  this.submitDocument();
  });
},


		openLocationDialog() {
			if (navigator && navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						this.userLatitude = position.coords.latitude;
						this.userLongitude = position.coords.longitude;
						this.submitLocation = true;
					},
					() => {
						this.userLatitude = "";
						this.userLongitude = "";
						this.submitLocation = true;
					}
				);
			}
		},
	},
};
</script>
